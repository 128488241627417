<template>
  <div>
    <Pane />
    <div class="container">
      <Design :phaseList="list" @refresh="getList" />
    </div>
  </div>
</template>

<script>
import Design from "./design";
import { fetchModelList } from "@/api/project";
export default {
  components: {
    Design,
  },

  data() {
    return {
      id: null,
      list: [],
    };
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id) {
      this.id = id;
      this.getList();
    }
  },

  methods: {
    getList() {
      fetchModelList({
        id: this.id,
      }).then((res) => {
        if (Array.isArray(res)) {
          this.list = res;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
}
</style>