<template>
  <div class="progress-wrapper">
    <a-button type="primary" style="width: 170px">{{
      phase.phaseName
    }}</a-button>

    <div style="flex: 1; margin-left: 8px">
      <div class="left">
        <span>当前进度：{{ phase.progress }}%</span>

        <a-button
          icon="upload"
          type="primary"
          style="margin-left: 8px"
          @click="add"
          >上传确认资料</a-button
        >

        <a-button
          type="link"
          v-if="!showFile"
          @click="showFile = true"
          style="margin-left: auto"
        >
          <span> 查看确认文件（{{ showList.length }}） </span>
          <a-icon type="down" />
        </a-button>
        <a-button
          type="link"
          v-else
          @click="showFile = false"
          style="margin-left: auto"
        >
          <span> 隐藏确认文件 </span>
          <a-icon type="up" />
        </a-button>
      </div>

      <Padding v-if="showFile" />

      <div style="background: #fff" v-if="showFile">
        <a-table bordered :data-source="showList" :pagination="false">
          <a-table-column title="资料类型" data-index="typeValue">
          </a-table-column>
          <a-table-column title="文件名称">
            <template slot-scope="text">
              <a download target="_blank" :href="text.filePath">{{
                text.fileName ? text.fileName : "--"
              }}</a>
            </template>
          </a-table-column>
          <a-table-column title="操作" align="center">
            <template slot-scope="text">
              <span style="color: #f00; cursor: pointer" @click="remove(text)"
                >删除</span
              >
            </template>
          </a-table-column>
        </a-table>
      </div>
    </div>

    <UploadModal
      :visible="visible"
      @setVisible="setVisible"
      :phase="phase"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import { deleteFile } from "@/api/project";
import { mapGetters } from "vuex";
import UploadModal from "./upload-modal";
export default {
  props: {
    phase: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    UploadModal,
  },

  data() {
    return {
      visible: false,
      showFile: true, // 是否显示文件
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    fileTypeList() {
      return this.findDataDict("fileType");
    },

    showList() {
      // 展示在页面上的文件列表，需要添加文件类型，从数据字典中查找
      const typeObj = this.fileTypeList.find(
        (item) => item.value === this.phase.phaseCode
      );
      if (typeObj && Array.isArray(typeObj.children)) {
        let newList = [];
        for (let i = 0; i < this.list.length; i++) {
          const element = this.list[i];
          const result = typeObj.children.find(
            (item) => item.value === element.type
          );

          if (result) {
            newList.push({
              ...element,
              typeValue: result.name,
            });
          }
        }

        return newList;
      } else {
        return this.list;
      }
    },

    // 上传的文件列表
    list() {
      return Array.isArray(this.phase.outputList) ? this.phase.outputList : [];
    },
  },

  methods: {
    add() {
      this.visible = true;
    },

    setVisible(payload) {
      this.visible = payload;
    },

    remove(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除这个文件吗？",
        onOk() {
          deleteFile({
            id: text.id,
          }).then(() => {
            that.$emit("refresh");
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.progress-wrapper {
  padding: 12px;
  background-color: #f5f5f5;
  display: flex;
  margin-bottom: 12px;
}
.progress {
  font-size: 16px;
  font-weight: bold;
  color: #1890ff;
}
</style>






