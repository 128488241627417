<template>
  <div>
    <Title title="项目阶段进度" />

    <div class="wrapper" v-if="phaseList.length > 0">
      <Progress
        v-for="(item, index) in phaseList"
        :key="index"
        :phase="item"
        @refresh="$emit('refresh')"
      />
    </div>

    <div class="wrapper" v-else>
      <a-empty />
    </div>
  </div>
</template>

<script>
import Title from "../title";
import Progress from "./progress";
export default {
  components: {
    Title,
    Progress,
  },

  props: {
    phaseList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>


<style lang="less" scoped>
.wrapper {
  background-color: #fff;
  padding: 12px;

  .phase-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 8px;

    .content {
      grid-column-start: 1;
      grid-column-end: 3;
      padding: 12px;
      background-color: #f5f5f5;
    }

    .detail {
      grid-column-start: 3;
      grid-column-end: 6;
      height: 400px;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .progress {
      color: #1890ff;
      font-size: 18px;
      font-weight: bold;
      margin-right: 2px;
    }
  }
}
</style>