<template>
  <a-modal title="上传确认资料" :visible="visible" @cancel="cancel" @ok="ok">
    <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="文件类型">
        <a-select placeholder="请选择文件类型" v-model="selectedType">
          <a-select-option
            v-for="item in typeList"
            :key="item.id"
            :value="item.value"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="确认资料">
        <FileUpload :multiple="true" @uploaded="uploaded">
          <a-button>请上传</a-button>
        </FileUpload>
        <div class="file-list">
          <div class="file" v-for="(item, index) in imageList" :key="item.id">
            <a :href="item.completePath">{{ item.name }}</a>
            <a-icon class="delete" type="delete" @click="remove(index)" />
          </div>
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import FileUpload from "@/components/file-upload";
import { mapGetters } from "vuex";
import { uploadFile } from "@/api/project";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    phase: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    FileUpload,
  },

  data() {
    return {
      typeList: [],
      selectedType: null,
      imageList: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        const res = this.findDataDict("fileType");
        const typeObj = res.find((item) => item.value === this.phase.phaseCode);
        if (typeObj && Array.isArray(typeObj.children)) {
          this.typeList = typeObj.children;
        }
      }
    },
  },

  methods: {
    uploaded(arr) {
      this.imageList = [...this.imageList, ...arr];
    },

    remove(index) {
      this.imageList.splice(index, 1);
    },

    cancel() {
      this.$emit("setVisible", false);
      this.selectedType = null;
      this.imageList = [];
    },
    ok() {
      if (this.imageList.length === 0) {
        this.$message.error("请上传文件");
        return;
      }
      if (!this.selectedType) {
        this.$message.error("请选择文件类型");
        return;
      }

      uploadFile([
        ...this.imageList.map((item) => ({
          ...item,
          fileId: item.id,
          type: this.selectedType,
          progressId: this.phase.id,
        })),
      ]).then(() => {
        this.$emit("refresh");
        this.cancel();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.upload {
  height: 128px;
  width: 128px;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  i {
    font-size: 32px;
    color: #999;
  }
  div {
    color: #666;
  }
}

.file-list {
  margin-top: 12px;
  .file {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color: #f9f9f9;
    }

    a {
      line-height: 2em;
    }

    .delete {
      &:hover {
        color: #1890ff;
        cursor: pointer;
      }
    }
  }
}
</style>